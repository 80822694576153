<template>
	<div>
		<a-form class="mb-4" :form="form" @submit="handleSubmit">
			<div class="row">
				<div class="col-md-9">
					<a-form-item class="m0" :label="`Nombre del ${product.is_service == 'true' ? 'servicio' : 'producto'}`">
						<a-input v-model="product.label" />
					</a-form-item>
				</div>
				<div class="col-md-3">
					<a-form-item class="m0" label="Unidad de medida">
						<a-select show-search option-filter-prop="children" :filter-option="filterOption" v-model="product.measurement_unit_id">
							<a-select-option v-for="(element, index) in measurementUnitsList" :key="index" :value="element.id">{{ `${element.sat_key} - ${element.unit_name}` }}</a-select-option>
						</a-select>
					</a-form-item>
					<small @click="getMeasurementUnitsList" class="pointer">Recarga</small>
				</div>
				<div class="col-md-4">
					<a-form-item class="m0" label="Clave de producto">
						<a-input-search placeholder="Código de barras o SKU del producto" ref="productSKU" v-model="product.sku" @search="onCreateSKU">
							<a-button type="primary" slot="enterButton">
								<a-icon type="setting" theme="filled" />
							</a-button>
						</a-input-search>
					</a-form-item>
				</div>
				<div class="col-md-4">
					<a-form-item class="m0" label="Clave alterna">
						<a-input v-model="product.alternate_sku" />
					</a-form-item>
				</div>
				<div class="col-md-4">
					<a-form-item class="m0" label="Clave SAT">
						<a-input-search enter-button v-model="product.sat_information.full_description" readOnly @search="openSATModal" />
					</a-form-item>
				</div>
				<div class="col-md-4">
					<a-form-item class="m0" label="Stock">
						<a-input v-model="product.stock" v-mask="'####'" />
					</a-form-item>
				</div>
				<div class="col-md-4">
					<a-form-item class="m0" label="Proveedor">
						<a-select show-search allow-clear option-filter-prop="children" :filter-option="filterOption" v-model="product.supplier_id">
							<a-select-option v-for="(element, index) in suppliersList" :key="index" :value="element.id">{{ element.tradename.toUpperCase() }}</a-select-option>
						</a-select>
					</a-form-item>
					<small @click="getSuppliersList" class="pointer">Recarga</small>
				</div>
				<div class="col-md-4">
					<a-form-item class="m0" label="Categoría/Subcategoría">
						<a-select show-search allow-clear option-filter-prop="children" :filter-option="filterOption" v-model="product.subcategory">
							<a-select-option v-for="(element, index) in fullProductCategoriesList" :key="index" :value="element.id">{{ element.label }}</a-select-option>
						</a-select>
					</a-form-item>
					<small @click="getFullCategoriesList" class="pointer">Recarga</small>
				</div>
			</div>
			<div class="row">
				<div class="col-md-3">
					<a-form-item class="m0" label="Costo unitario">
						<a-input prefix="$" v-money="money" v-model="product.unit_cost" />
					</a-form-item>
				</div>
				<div class="col-md-3">
					<a-form-item class="m0" label="Precio de venta a público">
						<a-input prefix="$" v-money="money" v-model="product.prices.sale_price" />
					</a-form-item>
				</div>
				<div class="col-md-3">
					<a-form-item class="m0" label="Umbral de existencia mínimo">
						<a-input prefix="#" v-mask="'####'" v-model="product.minimum_threshold" />
					</a-form-item>
				</div>
				<div class="col-md-3">
					<a-form-item class="m0" label="Umbral de existencia máximo">
						<a-input prefix="#" v-mask="'####'" v-model="product.maximum_threshold" />
					</a-form-item>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<hr class="hrText" data-content="Tienda en Linea s2r.mx" />
				</div>
				<div class="col-md-3">
					<a-checkbox :checked="!!product.in_shop" @change="setShops('in_shop')"> Activo tienda en línea </a-checkbox>
				</div>
				<div class="col-md-3">
					<a-form-item class="m0" label="Path WC">
						<a-input v-model="product.wc_path" />
					</a-form-item>
				</div>
				<div class="col-md-3">
					<a-form-item class="m0" label="Descuento %">
						<a-input prefix="%" v-mask="'##'" v-model="product.wc_discount" />
					</a-form-item>
				</div>
				<div class="col-md-3">
					<a-form-item class="m0" label="Precio tienda en línea">
						<a-input prefix="$" v-money="money" v-model="product.wc_price" />
					</a-form-item>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<hr class="hrText" />
				</div>
				<div class="col-md-4 text-left">
					<a-button class="ml5 btn btn-warning" icon="arrow-left" @click="onCancel">Regresar</a-button>
				</div>
				<div class="col-md-8 text-right">
					<a-button icon="save" class="btn btn-success" htmlType="submit">Guardar</a-button>
				</div>
			</div>
		</a-form>
		<searchSATProductServiceComponent :visible="modal.visible" @success="onCloseModal" @selectedRecord="onSelectedRecord" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import { productModel } from '@/constants'
import _ from 'lodash'
import { mask } from 'vue-the-mask'
import { VMoney } from 'v-money'
import searchSATProductServiceComponent from '@/components/searchSATProductService'
import utilities from '@/services/utilities'
import numeral from 'numeral'
import moment from 'moment'

export default {
	name: 'product_generalComponent',
	components: {
		searchSATProductServiceComponent,
	},
	directives: {
		mask,
		money: VMoney,
	},
	computed: {
		...mapGetters('products', ['fullProductCategoriesList', 'actualProduct']),
		...mapGetters('measurementUnits', ['measurementUnitsList']),
		...mapGetters('suppliers', ['suppliersList']),
		spinnerLoader() {
			return this.$store.state.products.spinnerLoader
		},
	},
	beforeMount() {
		// categorías de productos
		if (!this.fullProductCategoriesList.length) {
			this.getFullCategoriesList()
		}
		// unidades de medida
		if (!this.measurementUnitsList.length) {
			this.getMeasurementUnitsList()
		}
		if (utilities.objectValidate(this.$route, 'params.id', false)) {
			let product = _.cloneDeep({
				...this.product,
				...this.actualProduct,
				wc_price: utilities.objectValidate(this.actualProduct, 'wc_price', false) ? numeral(this.actualProduct.wc_price).format('0,0.00') : numeral(0).format('0,0.00'),
				stock: utilities.objectValidate(this.actualProduct, 'stock', false) ? numeral(this.actualProduct.stock).format('0') : numeral(0).format('0'),
			})

			product.prices = {
				...product.prices,
				percent: numeral(product.prices.percent).format('0,0.00'),
				fixed_price: numeral(product.prices.fixed_price).format('0,0.00'),
				vat: numeral(product.prices.vat).format('0,0.00'),
				sale_price: numeral(product.prices.sale_price).format('0,0.00'),
			}

			this.product = _.cloneDeep(product)
		}
	},
	mounted() {
		if (!utilities.objectValidate(this.$route, 'params.id', false)) {
			this.$refs.productSKU.focus()
		}
		if (!this.suppliersList.length) {
			this.getSuppliersList()
		}
	},
	data: function () {
		return {
			form: this.$form.createForm(this),
			money: {
				decimal: '.',
				thousands: ',',
				precision: 2,
			},
			product: _.cloneDeep(productModel.general),
			modal: {
				visible: false,
				title: '',
			},
			subCategoriesList: [],
			disabledSubcategory: true,
		}
	},
	methods: {
		onCancel() {
			this.$router.replace('/boutique/products')
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
		handleSubmit(e) {
			e.preventDefault()
			let errors = []

			if (!utilities.objectValidate(this.product, 'sat_information.full_description', false)) {
				errors.push('- Ingresa clave del SAT')
			}
			if (!utilities.objectValidate(this.product, 'sku', false)) {
				errors.push('- Ingresa código de barras o SKU del producto')
			}
			if (!utilities.objectValidate(this.product, 'subcategory', false)) {
				errors.push(`- Ingresa categoría/subcategoría del ${this.product.is_service == 'true' ? 'servicio' : 'producto'}`)
			}
			if (!utilities.objectValidate(this.product, 'label', false)) {
				errors.push(`- Ingresa nombre del producto`)
			}
			if (!utilities.objectValidate(this.product, 'unit_cost', false) || Number(this.product.unit_cost) < 1) {
				errors.push('- Ingresa costo unitario')
			}
			if (!utilities.objectValidate(this.product, 'internal_use', false) && !utilities.objectValidate(this.product, 'prices.sale_price', false)) {
				errors.push('- Ingresa precio de venta para el producto')
			}
			// Validación de costo vs precio de venta
			if (numeral(this.product.prices.sale_price).value() <= numeral(this.product.unit_cost).value()) {
				errors.push('- El precio de venta no puede ser menor o igual al costo de adquisición')
			}

			if (!utilities.objectValidate(this.product, 'measurement_unit_id', false)) {
				errors.push('- Selecciona unidad de medida')
			}
			if (this.product.stock == '') {
				errors.push('- Ingresa stock')
			}
			// valida si está habilitada la bandera de tienda en linea y si no tiene un precio publicado
			let wc_price = numeral(_.clone(this.product.wc_price)).value()
			if (this.product.in_shop && !wc_price > 0) {
				errors.push('- Ingresa precio del producto para la tienda en linea.')
			}
			// valida que sea asignado un proveedor
			if (this.product.supplier == '') {
				errors.push('- Selecciona un proveedor.')
			}

			if (errors.length) {
				Swal.fire({
					title: 'Atención',
					html: errors.join('<br>'),
					icon: 'warning',
					showCancelButton: false,
					confirmButtonText: 'Ok',
				})
				return false
			}
			this.product.is_boutique = true

			if (utilities.objectValidate(this.$route, 'params.id', false)) {
				this.product.id = Number(this.$route.params.id)
				this.$store.dispatch('products/UPDATE', this.product)
			} else {
				this.$store.dispatch('products/CREATE', this.product)
			}
		},
		onCreateSKU() {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas generar un SKU para este producto?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					this.product.sku = moment().valueOf()
				}
			})
		},
		onCloseModal() {
			this.modal.visible = false
		},
		openSATModal() {
			this.modal.visible = true
		},
		onSelectedRecord(record) {
			this.product.sat_information = {
				..._.cloneDeep(record),
				full_description: `${record.sat_key} - ${record.description}`,
			}
			this.onCloseModal()
		},
		onCategorySelected() {
			this.subCategoriesList = this.productSubCategoriesList.filter((e) => e.category_id == this.product.category)
			this.disabledSubcategory = false
		},
		getFullCategoriesList() {
			this.$store.dispatch('products/GET_CAT_SUBCAT_LIST')
		},
		getMeasurementUnitsList() {
			this.$store.dispatch('measurementUnits/GET')
		},
		setShops(type) {
			this.product[type] = !this.product[type]
		},
		getSuppliersList() {
			this.$store.dispatch('suppliers/GET_NO_REDIRECT')
		},
	},
	watch: {
		'product.is_service'(newValue) {
			let isService = newValue == 'true' ? true : false
			if (isService) {
				// 4 es el id de unidad de medida de servicio
				utilities.objectUpdate(this.product, 'measurement_unit_id', 4)
			} else {
				// 4 es el id de unidad de medida de pieza
				utilities.objectUpdate(this.product, 'measurement_unit_id', 1)
			}
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>